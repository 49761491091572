<template>
    <div class="battery-details-wrap">
        <div class="details-title uk-flex uk-flex-between uk-gap-m" @click="toggleExpand">

            <h2 class="tr-font-m tr-font-bold uk-margin-remove">
                {{translate('talron.battery.detailsTitle')}}
            </h2>
            <button
                style="appearance: none;"
                :aria-expanded="finalExpand"
                :aria-label="translate('talron.battery.detailsTitle')"
                :aria-hidden=" !finalExpand"
                class="uk-animation-fade uk-animation-fast" uk-icon="talron-fold"
                v-show="finalExpand"/>
            <button
                style="appearance: none;"
                :aria-expanded="finalExpand"
                :aria-hidden="finalExpand"
                :aria-label="translate('talron.battery.detailsTitle')"
                class="uk-animation-fade uk-animation-fast" uk-icon="talron-expand"
                v-show=" ! finalExpand"/>
        </div>
        <ul class="details-table uk-animation-fade uk-animation-fast"
            v-show="finalExpand">
            <li class="detail uk-flex uk-flex-between">
                <span class="detail-title">{{ translate('talron.battery.detailManufacturerLabel') }}</span>
                <span class="detail-content">{{ finalBatteryInfo.manufacturer }}</span>
            </li>
            <li class="detail uk-flex uk-flex-between">
                <span class="detail-title">{{ translate('talron.battery.detailSerialNumberLabel') }}</span>
                <span class="detail-content">{{ finalBatteryInfo.serialNumber }}</span>
            </li>
            <li class="detail uk-flex uk-flex-between">
                <span class="detail-title">{{ translate('talron.battery.detailAmperPerHourLabel') }}</span>
                <span class="detail-content">{{ finalBatteryInfo.amperPerHour }}</span>
            </li>
            <li class="detail uk-flex uk-flex-between">
                <span class="detail-title">{{ translate('talron.battery.detailTypeLabel') }}</span>
                <span class="detail-content">{{ translate(finalBatteryInfo.type) }}</span>
            </li>
            <li class="detail uk-flex uk-flex-between">
                <span class="detail-title">{{ translate('talron.battery.detailLocationLabel') }}</span>
                <span class="detail-content">{{ finalBatteryInfo.location }}</span>
            </li>
        </ul>
        <div class="refresh-info uk-flex uk-flex-center uk-flex-middle"
             style="margin-top: 30px; gap: 4px;"
             v-if="finalBatteryInfo.warranty.wasRefreshed">
            <span uk-icon="reload"></span><span> </span><span>{{translate('talron.battery.wasRefreshedOn') + ' ' + refreshDate}}</span>
        </div>
    </div>
</template>

<script setup>

</script>

<script>
export default {
    props: {
        batteryInfo: {
            type: [Object, Boolean],
            default: false
        },
        expand: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            localExpand: null,
        }
    },
    computed: {
        storeBatteryInfo() {
            return this.$store.getters['talron/battery'];
        },
        finalBatteryInfo() {
            return this.batteryInfo ? this.batteryInfo : this.storeBatteryInfo;
        },
        finalExpand() {
            if (typeof this.localExpand === 'boolean') {
                return this.localExpand;
            }

            return this.expand;
        },
        refreshDate () {
            let refreshTime = Number(this.finalBatteryInfo.warranty.wasRefreshedOnTime) * 1000;

            if ( ! isNaN(refreshTime)) {
                return this.$d(new Date(refreshTime));
            } else {
                return '-'
            }

        }
    },
    methods: {
        toggleExpand() {
            if (typeof this.localExpand === 'boolean') {
                this.localExpand = ! this.localExpand;
                return;
            }

            this.localExpand = ! this.expand;
        }
    }
};
</script>

<style lang="scss" scoped>
.details-title {
    cursor: pointer;
    padding: 10px 18px;

    @media (min-width: 1200px) {
        font-weight: bold;
    }
}

.details-table {
    padding: 0;
    list-style: none;
}
.detail {
    padding: 10px 18px;

    &:nth-child(odd) {
        background-color: var(--global-muted-background);
    }

}

.refresh-info {
    font-size: 12px;
    font-weight: bold;
    color: var(--global-success-background);

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}


</style>



